import Lenis from '@studio-freight/lenis'
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import CSSPlugin from 'gsap/CSSPlugin';
gsap.registerPlugin(ScrollTrigger, CSSPlugin);
import Swiper from 'swiper';
import { EffectFade } from 'swiper/modules';
Swiper.use([EffectFade]);

const setScrollbarWidth = () => {
	const scrollbarWidth = window.innerWidth - document.documentElement.clientWidth;
	// カスタムプロパティの値を更新する
	document.documentElement.style.setProperty('--scrollbar', `${scrollbarWidth}px`);
};

setScrollbarWidth();

window.addEventListener("pageshow", function () {
	const lenis = new Lenis({
		lerp: 1,
		duration: 0,
		easing: function (t) { return (t === 1 ? 1 : 1 - Math.pow(2, -10 * t)) },
		smooth: true,
		smoothTouch: false,
	});

	function raf(time) {
		lenis.raf(time);
		ScrollTrigger.update();
		requestAnimationFrame(raf);
	}

	requestAnimationFrame(raf);

	//lenis.scrollTo("html", 'duration:0');

	//console.log(lenis);

	const body = document.querySelector('body');
	const btn = document.querySelector('#hbvBtn');
	const menu_target = document.querySelector('.js-header__menu');
	const menu_overlay = document.querySelector('.overlay');
	const hlbtn = document.querySelector('#hbvBtn');
	const hlbtn__Close = document.querySelector('#hbvBtn__Close');
	const hlmenu_target = document.querySelector('#header__lang__body');


	const anchorLinks = document.querySelectorAll('a[href^="#"]');
	const anchorLinksArr = Array.prototype.slice.call(anchorLinks);
	const header = document.querySelector('.header');
	const anchorHash = location.hash;

	//let state = false;
	hlbtn.addEventListener("click", function () {
		//let nextDom = this.nextElementSibling;

		this.setAttribute('aria-expanded', 'true');
		//
		//menu_target.setAttribute('aria-hidden', 'false');
		//
		//menu_overlay.classList.add('open');
		//
		//body.classList.add('fixed');
		gsap.to(menu_target, {
			ease: "expo.out",
			duration: .56,
			xPercent: -100,

		});

		gsap.to(menu_overlay, {
			ease: "power3.out",
			duration: .4,
			autoAlpha: 1,
			display: "block"
		});
		//hlbtn.style.marginRight = window.innerWidth - document.body.clientWidth + 'px';
		//this.style.marginRight = window.innerWidth - document.body.clientWidth + 'px';
		body.style.paddingRight = window.innerWidth - document.body.clientWidth + 'px';
		lenis.stop();
	});
	hlbtn__Close.addEventListener("click", function () {
		hlbtn.setAttribute('aria-expanded', 'false');

		//menu_target.setAttribute('aria-hidden', 'true');
		gsap.to(menu_target, {
			ease: "expo.out",
			duration: .56,
			xPercent: 0,

		});

		gsap.to(menu_overlay, {
			ease: "power3.out",
			duration: .4,
			autoAlpha: 0,
			display: "none"

		});

		body.style.paddingRight = '0px';
		lenis.start();
	});
	menu_overlay.addEventListener("click", function () {
		hlbtn.setAttribute('aria-expanded', 'false');
		//menu_target.setAttribute('aria-hidden', 'true');
		gsap.to(menu_target, {
			ease: "expo.out",
			duration: .56,
			xPercent: 0,

		});

		gsap.to(menu_overlay, {
			ease: "power3.out",
			duration: .4,
			autoAlpha: 0,
			display: "none"

		});
		body.style.paddingRight = '0px';
		lenis.start();
	});





	if (document.querySelector('#header__lang__btn')) {

		//let state = false;
		hlbtn.addEventListener("click", function () {
			if (this.getAttribute('aria-expanded') == 'false') {
				//
				this.setAttribute('aria-expanded', 'true');
				hlmenu_target.setAttribute('aria-hidden', 'false');
			} else {

				//
				this.setAttribute('aria-expanded', 'false');
				hlmenu_target.setAttribute('aria-hidden', 'true');
			}
		});
	}

	if (anchorHash) {
		window.scrollTo({
			top: 0,
			behavior: "instant"
		});
		const targetElement = document.querySelector(anchorHash);
		const targetOffsetTop = window.pageYOffset + targetElement.getBoundingClientRect().top;
		const headerHeight = header.offsetHeight;
		const totalScrollAmount = targetOffsetTop - headerHeight;

		targetElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
		var tm = 500;
		setTimeout(fn, tm);
	}

	anchorLinksArr.forEach(link => {
		link.addEventListener('click', e => {
			e.preventDefault();
			const targetId = link.hash;
			const targetElement = document.querySelector(targetId);
			const targetOffsetTop = window.pageYOffset + targetElement.getBoundingClientRect().top;
			const headerHeight = 0;
			//const totalScrollAmount = targetOffsetTop - headerHeight;
			const totalScrollAmount = targetOffsetTop - headerHeight;
			targetElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
		});
	});



	//
	/*
	const viewAnimTarget = document.querySelectorAll('[data-view-cont="true"]');

	viewAnimTarget.forEach((cont) => {
		let viewAnimItem = cont.querySelectorAll('[data-view-item]');
		//let viewAnimItemLength = viewAnimItem.length;

		const viewTimeline = gsap.timeline({
			scrollTrigger: {
				trigger: cont,
				start: "top center",
				//markers:true,
			},
		});

		viewTimeline.fromTo(
			viewAnimItem,
			{
				autoAlpha: 0,
				y: 30
			},
			{
				y: 0,
				autoAlpha: 1,
				duration: .4,
				stagger: {
					each: .1,
				}
			},

		);

		viewAnimItem.forEach((item) => {
			//console.log(item);
		});
	});*/

});
window.addEventListener("resize", function () {
	setScrollbarWidth();
});
